.video-embed {
  position: relative;
  // padding-bottom: 56.25%; /*16:9*/
  padding-bottom: 42.25%; //- More smaller
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  max-width: 980px;
  margin: 0 auto;
}

.video-embed iframe,
.video-embed object,
.video-embed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
