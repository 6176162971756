//- Box - Soit les encarts rectangulaires / larges version desktop
.box{
    position: relative;
    display: block;
    // width: 300px;
    margin: calculateRem(11px) 0;
    height: calculateRem(100px);

    background: #FFF;

    font-size: 0;
    text-align: left;
    text-decoration: none;

    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);

    //- Box title
    .box-title{
        margin: 0;
        font: bold #{calculateRem(14px)}/#{calculateRem(19px)} $open_sans, sans-serif;
        text-transform: uppercase;
        color: $blue-darken;

        transition: color .2s ease-in-out;
    }

    //- Box subtitle
    .box-subtitle{
        margin-top: calculateRem(5px);
        margin-bottom: 0;

        font: 600 #{calculateRem(12px)}/#{calculateRem(14px)} $open_sans, sans-serif;
        color: #4a4a4a;
        transition: color .2s ease-in-out;
    }

    //- Arrow icon
    i{
        margin-top: calculateRem(8px);

    }

    &:hover, &:focus{
        .box-title{
            color: $fushia;
        }

        .box-subtitle{
            color: $fushia;
        }
    }

    @include breakpoint($desktop){
        .box-subtitle{
            font-size: calculateRem(14px);
            line-height: calculateRem(19px);
            color: $blue-darken;
        }

        i{
            position: absolute;
            top: 50%;
            right: 20px;

            margin-top: 0;

            transform: translateY(-50%);
        }
    }

}

//- Container of image and content inner .box
.box-left, .box-right{
    display: inline-block;
    vertical-align: middle;
}

//- Specific rule for box container of content text
.box-right{
    padding: 0 20px;
    text-align: left;
    max-width: calc(100% - 70px);
    width: auto;

    @include breakpoint($desktop){
        $width_to_remove : 250px + 40px; //- 250px -> Image Width && 40px -> Width of Arrow

        max-width: calc(100% - #{$width_to_remove});
    }
}

//- Box image - left side
.box-left{
    position: relative;
    width: 70px;
    height: 100%;
    overflow: hidden;

    @include breakpoint($desktop){
        width: 250px;
    }
}

//- Box image rules
.box-image{
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    object-fit: cover;
    overflow: hidden;

    @include breakpoint($desktop){
        object-fit: contain;
    }
}

//- FIX IE11 - Object-fit image
_:-ms-fullscreen, :root .box-image{
    max-width: none;
    transform: translateX(-40%);

    @include breakpoint($desktop){
        transform: none;
        max-width: 100%;
    }
}
