/**
 * Header - 1
 */
//- Header general rules
.header-1{
    display: none;

    background: $blue;
    color: #FFF;


    .container{
        text-align: center;
        font-size: 0;
    }

    a{
        color: #FFF;
        text-decoration: none;
    }

    @include breakpoint($tablet){
        display: block;
    };

}

//- Contact (Left) - Social (Right) block
.header-1 .contact-tools, .header-1 .social-links{
    display: inline-block;
    width: 50%;
    text-align: left;
    line-height: 50px;
    font-size: calculateRem(16px);
    vertical-align: middle;
}

.header-1 .social-links{
    text-align: right;
}


//- Contact tools - Header left side
.contact-tel, .contact-mail{
    position: relative;
    display: inline-block;

    i{
        margin-right: 5px;
    }

    a{
        font: 600 #{calculateRem(14px)} / #{calculateRem(14px)} $open_sans, sans-serif;
        opacity: 1;
        transition: opacity .2s ease-in-out;

        &:hover, &:focus{
            text-decoration: none;
            color: #FFF;
            opacity: 0.5;
        }
    }
}

.contact-mail .icon-combined-shape{
    font-size: .65rem;
}

.header-1 .contact-tools .sep{
    display: inline-block;
    padding: 0 20px;
}

.contact-tel{
    margin-right: 40px;

    &::after{
        content: '';
        display: block;
        height: 18px;
        width: 1px;
        background: #FFF;
        position: absolute;
        top: 50%;
        right: -20px;

        transform: translateY(-50%);
    }
}

//- Social Links - Header right side
.header-1 .social-links a{
    display: inline-block;
    padding: 0 35px 0 0;
    opacity: 1;

    font: 600 #{calculateRem(20px)} / #{calculateRem(50px)} $open_sans, sans-serif;

    transition: opacity .2s ease-in-out;

    &:last-child{
        padding: 0;

    }

    //- Specific rules for each icon

    .icon-fb-icon{
        font-size: calculateRem(20px)
    }

    .icon-tw-icon{
        font-size: calculateRem(16px)
    }

    .icon-yt-icon{
        font-size: calculateRem(18px)
    }
    .icon-in-icon{
        font-size: calculateRem(18px)
    }

    &:hover, &:focus{
        color: inherit;
        text-decoration: none;
        opacity: 0.5;
    }
}
