//- Position of button
.navicon-btn{
    position: absolute;
    top: 0;
    right: 0;

    height: 80px;
    width: 80px;

    background: transparent;
    border: none;
    border-left: 1px solid #d8d8d8;

    cursor: pointer; //- For IE

    @include breakpoint($tablet){
        display: none;
    }

}

//- Burger icon in css when nav is closed
.navicon-btn .navicon-burger{
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;

    height: 2px;
    width: 30px;

    background: #4a4a4a;
    border-radius: 2px;

    transform: translate(-50%, -50%);
    backface-visibility: hidden;
    transition: background .2s ease-in-out;

    &::before, &::after{
        content: '';
        position: absolute;
        left: 0;
        display: block;

        width: 30px;
        height: 2px;
        border-radius: 2px;

        background: #4a4a4a;
        backface-visibility: hidden;

        transition: transform .2s ease-in-out, top .2s ease-in-out, bottom .2s ease-in-out;

    }

    &::before{
        top: -8px;
        transform-origin: center;
    }

    &::after{
        bottom: -8px;
        transform-origin: center;
    }
}

//- Cross close when nav is-open
.navicon-btn.is-open .navicon-burger{
    background: transparent;
    // transform: translate(-50%, -50%) scaleX(0);
    //
    &::before{
        top: 0;
        transform: rotateZ(-45deg);
    }

    &::after{
        bottom: 0;
        transform: rotateZ(45deg);
    }
}
