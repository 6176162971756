.sticky{
    position: fixed;
    width: 100%;

    z-index: 2;

    top: 0;

    .header-navigation{
        height: 80px;
    }

    nav a {
        position: relative;

        line-height: calculateRem(80px);

        &::after {

            content: '';

            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;

            height: 5px;

            background: $fushia;

            transform: scaleY(0);
            transform-origin: bottom;

            transition: transform .2s ease-in-out;
        }

        &:hover::after{

            transform: scaleY(1);
        }

        &.active::after {

            transform: scaleY(1);

        }
    }




    .logo-link{
        transform: scale(0.8);
    }
}

header{
    position: fixed;
    width: 100%;

    z-index: 2;

    top: 0;
}
