/**
 * Encarts.scss
 */

.encart{
    position: relative;
    min-height: 162px;
    margin: 20px 0;


    font-size: 0;
    text-align: left;
    text-decoration: none;

    border: solid 1px #f4f5f8;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);

    .btn{
        margin-top: calculateRem(15px);
    }

    //- Pour ajouter une ombre, certains de base n'en n'ont pas
    &.encart-shadow{
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
        height: auto;
    }
}

.encart-title{
    margin: 0;
    font: 600 #{calculateRem(13px)}/#{calculateRem(14px)} $montserrat, serif;
    color: $blue-darken;

    @include breakpoint(640px){
        font: 600 #{calculateRem(15px)}/#{calculateRem(20px)} $montserrat, serif;
    }
}

.encart-left, .encart-right{
    display: inline-block;
    vertical-align: middle;
}

//- Specific rule for box container of content text
.encart-right{
    padding: 0 20px;
    text-align: left;
    max-width: calc(100% - 110px);
    width: auto;
}

.encart.encart-shadow .encart-right{
    padding: 0 25px;
}

//- Encart image - left side
.encart-left{
    position: relative;
    width: 110px;
    min-height: 162px;
    height: 100%;
    overflow: hidden;
}


.encart-image{
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    object-fit: cover;
}

.encart-date{
    display: block;
    margin-bottom: 7px;
    font: 600 #{calculateRem(12px)}/#{calculateRem(12px)} $montserrat, sans-serif;
    color: $blue-darken;
}

.encart-location{
    display: block;
    margin-bottom: 10px;
    font: 500 #{calculateRem(12px)}/#{calculateRem(12px)} $montserrat, sans-serif;
    letter-spacing: -0.02rem;
    color: $blue-darken;
}
