.section{
    position: relative;
    background: #FFF;

    padding: calculateRem(50px) 0;

    &.section-grey{
        background: #f4f5f8;
    }

    @include breakpoint($desktop){
        padding: calculateRem(100px) 0;
    }

    &.section-small{
        padding: calculateRem(60px) 0;
    }
}

.section.section-primary{
    padding: calculateRem(150px) 0 calculateRem(50px);


    background-color: #FFF;

    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 150px;

        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
    }

    @include breakpoint($tablet){
        padding: calculateRem(300px) 0 calculateRem(100px);

        &::before{
            height: 300px;
        }
    }

    @include breakpoint($desktop){
        padding: calculateRem(470px) 0 calculateRem(100px);

        &::before{
            height: 470px;
        }
    }
}



//- Huge first section title
.section-primary-title-container{
    display: inline-block;
    position: relative;

    margin-bottom: -50%;
    padding: 20px 15px;

    background: #FFF;

    transform: translate(0, -65%);

    @include breakpoint($desktop){
        transform: translate(-60px, -65%);
        padding: 35px 60px;
    }

}

.section-primary-title{
    position: relative;
    font: normal #{calculateRem(24px)}/#{calculateRem(28px)} $open_sans, sans-serif;
    color: $blue-darken;

    &::after{
        content: '';
        position: absolute;
        left: 0;
        bottom: -15px;

        display: block;
        width: 70px;
        height: 2px;
        background: $fushia;
    }

    @include breakpoint($tablet){
        // font: normal #{calculateRem(48px)}/#{calculateRem(32px)} $open_sans, sans-serif;
        font: normal #{calculateRem(32px)}/#{calculateRem(32px)} $open_sans, sans-serif;
    }

    @include breakpoint($desktop){
        // font: normal #{calculateRem(48px)}/#{calculateRem(32px)} $open_sans, sans-serif;
        font: normal #{calculateRem(48px)}/#{calculateRem(48px)} $open_sans, sans-serif;

        &::after{
            bottom: -30px;

            width: 170px;
        }
    }
}

//- Classic section title - Centered
.section-title{
    position: relative;
    margin: 0 0 50px;
    padding: 0;

    font: normal #{calculateRem(24px)}/#{calculateRem(30px)} $open_sans, sans-serif;
    line-height: normal;
    color: $blue-darken;
    text-align: center;

    &::after{
        content: '';
        display: block;
        position: absolute;
        bottom: -20px;
        left: 50%;

        width: 100px;
        height: 2px;

        background: $fushia;

        transform: translateX(-50%);
    }

    @include breakpoint($tablet){
        font: normal #{calculateRem(28px)}/#{calculateRem(36px)} $open_sans, sans-serif;
    }

    @include breakpoint($desktop){
        font: normal #{calculateRem(36px)}/#{calculateRem(36px)} $open_sans, sans-serif;
    }
}

//- Little title
.section-subtitle{
    margin: 0 0 20px;
    font: 600 #{calculateRem(24px)}/#{calculateRem(26px)} $open_sans, sans-serif;
    color: $blue-darken;

    &.fushia{
        color: $fushia;
    }
}
