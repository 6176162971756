/* FEUILLE DE STYLE GLOBALE */

html {
  height: 100%;
}

body {
    min-height: 100%;
    margin: 0;
    font: normal #{calculateRem(14px)}/#{calculateRem(26px)} $open_sans, sans-serif;
    color: $blue-darken;

    transition: padding-top .2s ease-in-out;

    @include breakpoint($tablet){
        padding-top: 172px;
    }

}

strong{
    font-weight: 700;
}

pre{
    max-width: 100%;
    white-space: normal;
}

/* Skip menu accessibility tool */
.skip-menu {
  max-width: 970px;
  position: relative;
  margin: 0 auto; }

.skip-menu a {
  left: -7000px;
  padding: 10px;
  position: absolute;
  text-decoration: none;
  z-index: 1000;
  display: inline-block;
  // background-color: #666;
  background-color: #212121;
  color: #FFF;
}

.skip-menu a:focus {
  left: 0;
  color: #FFF;
  top: 0.2em; }

/* bannière du consentement des cookies de stats ou autre */
.cookie-banner {
  position: fixed;
  z-index: 100000;
  left: 0;
  width: 100%;
  text-align: center; }

.cookie-banner-message {
  margin: 0;
  padding: 10px;
  color: #fff;
  background-color: #3a3936; }

.cookie-banner-message a {
  text-decoration: underline;
  color: #fff; }

.cookie-banner-message .btn-save,
.popin-cookie .btn-save,
.cookie-banner-message a.btn-save,
.popin-cookie a.btn-save {
  display: inline-block;
  margin: 5px 2px;
  padding: 10px 16px;
  text-decoration: none;
  border: none; }

.popin-cookie {
  position: absolute;
  z-index: 10000;
  top: 40%;
  left: 50%;
  width: 300px;
  margin-top: 0;
  margin-left: -150px;
  padding: 10px 10px;
  text-align: center;
  opacity: 1;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 0 30px 1px #797979; }

.cookie-msg {
  font-size: 12px;
  z-index: 10;
  padding: 5px;
  text-align: center;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 0 30px 1px #797979; }

p{
    font: 600 #{calculateRem(14px)}/#{calculateRem(22px)} $open_sans, sans-serif;
    color: $blue-darken;
    margin-bottom: 20px;

}

.mobile-only{
    @include breakpoint($tablet){
        display: none;
    }
}

.container{
    //- Override bijoo grid container rule for mobile
    padding-right: 10px;
    padding-left: 10px;

    @include breakpoint($tablet){
        //- Why calc with one value ??? Founded in bijoo grid
        padding-right: calc(20px);
        padding-left: calc(20px);
    }
}

.list{
    list-style: disc;

    padding-left: 25px;
    padding-right: 10px;

    li{
        font-weight: 600;
        margin-bottom: 10px;
    }

    @include breakpoint($desktop){
        li{
            margin-bottom: 0;
        }
    }
}

.text-bold{
    font-weight: bold;
}

.page{
    padding: 80px 0 0;

    @include breakpoint($tablet){
        padding: 0;
    }
}

h1{
    @extend .section-primary-title;

    @include breakpoint($desktop){
        margin-bottom: 60px;
    }
}

h2{
    @extend .section-title;

    &.section-subtitle{
        text-align: left;

        &::after{
            display: none;
        }
    }

}

h3{
    @extend .section-subtitle;

}

h4{
    margin: 10px 0 15px;
    font: 600 #{calculateRem(20px)}/#{calculateRem(24px)} $open_sans, sans-serif;
    color: $blue-darken;

    &.fushia{
        color: $fushia;
    }
}

h5{
    margin: 5px 0 10px;
    font: 600 #{calculateRem(16px)}/#{calculateRem(20px)} $open_sans, sans-serif;
    color: $blue-darken;

    &.fushia{
        color: $fushia;
    }
}

h6{
    margin: 5px 0 10px;
    font: 600 #{calculateRem(14px)}/#{calculateRem(20px)} $open_sans, sans-serif;
    color: $blue-darken;

    &.fushia{
        color: $fushia;
    }
}
