/**
 * Regroupement de toutes les icônes
 */

.icon{
    display: inline-block;

    background-repeat: no-repeat;
    background-position: center;
}



//- Arrow icon
.icon-arrow{
    width: 25px;
    height: 8px;

    background-image: url('./assets/img/icons/arrow.svg');

    &.arrow-fushia{
        background-image: url('./assets/img/icons/arrow-fushia.svg');
    }
}
