footer{
    display: block;
    width: 100%;
    padding: 20px 30px;
    background: $blue-darken;

    text-align: center;
    font-size: 0;

    @include breakpoint($desktop){
        height: 65px;
        padding: 0;
    }
}

.footer-links{
    display: block;
    text-align: center;


    ul{
        list-style: none;
    }

    li{
        display: block;
    }

    a{
        font: 600 #{calculateRem(14px)}/#{calculateRem(30px)} $open_sans, sans-serif;
        color: #FFF;
        text-decoration: none;
        transition: opacity .2s ease-in-out;
        opacity: 1;

        &:hover, &:focus{
            color: #FFF;
            text-decoration: none;
            opacity: 0.5;
        }
    }

    @include breakpoint($desktop){
        display: inline-block;
        width: 50%;
        padding: 0;

        font-size: 0;
        text-align: left;
        vertical-align: middle;

        li{
            display: inline-block;

            &:first-child a{
                padding: 0 50px 0 0;
            }
        }

        a{
            padding: 0 50px;
            line-height: calculateRem(65px);
            color: #FFF;
            text-decoration: none;
        }
    }
}

.footer-address{
    margin: 15px 0 0;
    display: block;

    font: normal #{calculateRem(14px)}/#{calculateRem(20px)} $open_sans, sans-serif;
    text-align: center;
    color: #FFF;

    @include breakpoint($desktop){
        display: inline-block;
        vertical-align: middle;
        width: 50%;
        margin: 0;

        line-height: calculateRem(14px);
        text-align: right;

    }
}
