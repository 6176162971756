// Breakpoint Mixin - Simple version
// NB: Mobile First
// Usage:
// .mySelector
//     +breakpoint(600px)
//         content....
// Return :
// @media screen and (min-width: 600px){.mySelector{content...}};
@mixin breakpoint($value: 340px, $media: 'screen'){
    @media #{$media} and (min-width: #{$value}){
        @content
    }
}

$tablet : 768px;
$desktop : 1025px;
