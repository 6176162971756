a{
    font: 600 #{calculateRem(14px)}/#{calculateRem(26px)} $open_sans, sans-serif;
    text-decoration: none;
    color: $blue-darken;

    &.fushia{
        color: $fushia;
    }

    &:focus, &:hover{
        text-decoration: underline;
        color: $fushia;
    }
}

//- Example on Gouvernance page
.download-link{
    font: normal #{calculateRem(14px)}/#{calculateRem(26px)} $open_sans, sans-serif;
    text-decoration: none;

    i{
        &::before{
            display: none;
        }
    }

    .filename{
        margin: 0 10px 0 5px;

        font: normal #{calculateRem(14px)}/#{calculateRem(26px)} $open_sans, sans-serif;
        text-decoration: underline;
        color: $fushia;

        transition: color .2s ease-in-out;
    }

    .details{
        display: block;
        padding-left: 35px;
        color: $blue-darken;
    }

    @include breakpoint($desktop){
        span.details{
            display: inline-block;
            padding-left: 0;
        }
    }

    &:focus, &:hover{
        text-decoration: none;

        .filename{
            color: $fushia-darken;
        }
    }
}
