.header-navigation{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    z-index: 2;
    pointer-events: none;

    transition: height .2s ease-in-out;

    //- For box shadow problem on next section
    &::after{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        height: 100%;
        width: 100%;

        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);

        pointer-events: none;

        z-index: 2;
    }

    .container{
        padding: 0;
        // height: 100%;
        display: inline;
        pointer-events: all;
    }

    @include breakpoint($tablet){
        background: #FFF;
        position: relative;

        height: 122px;

        .container{
            font-size: 0;
            white-space: nowrap;

            display: block;
            padding-right: calc(20px);
            padding-left: calc(20px);
        }
    }
}


.header-navigation .logo-wrapper{
    display: block;
    padding-left: 10px;
    background: #fff;

    border-bottom: 1px solid #d8d8d8;

    height: 80px;
    line-height: 80px;

    @include breakpoint($tablet){
        display: inline-block;

        padding-left: 0;
        border-bottom: none;

        height: auto;
        line-height: auto;
    }
}


.header-navigation .logo-link{
    display: inline-block;
    vertical-align: middle;
    background: url('./assets/img/logo-cpe.svg') no-repeat center;
    width: calculateRem(185px);
    height: calculateRem(60px);

    // backface-visibility: hidden;

    transition: background-size .2s ease-in-out;

    @include breakpoint($tablet){
        width: 180px;
        height: 65px;
        line-height: 122px;
        background-size: 100%;

        transition: transform .2s ease-in-out;
    }

    @include breakpoint($desktop){
        width: 223px;
        height: 71px;
        line-height: 122px;
        background-size: 100%;

        transition: transform .2s ease-in-out;
    }
}

.header-navigation nav{
    pointer-events: all;

    display: none;
    background: #FFF;

    height: calc(100% - 80px);

    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        height: calc(100% - 149px);
    }

    li{
        //- Trick centering in unknow
        display: table;
        width: 100%;
        height: 25%;

        &:last-child a{
            border-bottom: none;
        }
    }

    a{
        //- Trick centering in unknow
        display: table-cell;
        vertical-align: middle;

        font: 600 #{calculateRem(18px)}/#{calculateRem(14px)} $open_sans, sans-serif;

        text-decoration: none;
        color: $blue-darken;
        text-align: center;

        border-bottom: 0.5px solid #e4e5e6;
    }

    @include breakpoint($tablet){
        display: inline-block;
        vertical-align: middle;

        width: calc(100% - 180px);
        height: 100%;

        //- Les items s'aligne sur la droite
        text-align: right;

        li{
            display: inline-block;
            vertical-align: middle;

            width: auto;
            height: 100%;

            &:first-child a{
                border-left: 1px solid #e4e5e6;
            }
        }

        a{
            display: block;

            font: 600 #{calculateRem(14px)}/#{calculateRem(122px)} $open_sans, sans-serif;

            width: calculateRem(150px);
            border-right: 0.5px solid #e4e5e6;
            border-bottom: none;

            transition: line-height .2s ease-in-out;

        }
    }

    @include breakpoint($desktop){
        width: calc(100% - 223px);

        a{
            font: 600 #{calculateRem(14px)}/#{calculateRem(122px)} $open_sans, sans-serif;

            width: calculateRem(238px);
        }
    }

}
