//- Font face
@font-face {
    font-family: 'oswald_regularregular';
    src: url('./assets/fonts/oswald-regular-webfont.woff2') format('woff2'),
         url('./assets/fonts/oswald-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


//- Open Sans
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
        url('./assets/fonts/subset-OpenSans-Bold.woff2') format('woff2'),
        url('./assets/fonts/subset-OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
        url('./assets/fonts/subset-OpenSans-Semibold.woff2') format('woff2'),
        url('./assets/fonts/subset-OpenSans-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), local('OpenSans'),
        url('./assets/fonts/subset-OpenSans.woff2') format('woff2'),
        url('./assets/fonts/subset-OpenSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


//- Montserrat
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('./assets/fonts/subset-Montserrat-Regular.woff2') format('woff2'),
        url('./assets/fonts/subset-Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
        url('./assets/fonts/subset-Montserrat-Medium.woff2') format('woff2'),
        url('./assets/fonts/subset-Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}




//- Fonts variables names
$oswald_regular : 'oswald_regularregular';
$montserrat : 'Montserrat';
$open_sans: 'Open Sans';


// Icomoon
@font-face {
  font-family: 'icomoon';
  src:  url('assets/fonts/icomoon.eot?o6ioui');
  src:  url('assets/fonts/icomoon.eot?o6ioui#iefix') format('embedded-opentype'),
    url('assets/fonts/icomoon.ttf?o6ioui') format('truetype'),
    url('assets/fonts/icomoon.woff?o6ioui') format('woff'),
    url('assets/fonts/icomoon.svg?o6ioui#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\e900";
}
.icon-combined-shape:before {
  content: "\e901";
}
.icon-fb-icon:before {
  content: "\e902";
}
.icon-in-icon:before {
  content: "\e903";
}
.icon-item-nav-icon-conference:before {
  content: "\e904";
}
.icon-item-nav-icon-exposants:before {
  content: "\e905";
}
.icon-item-nav-icon-guide:before {
  content: "\e906";
}
.icon-item-nav-icon-job:before {
  content: "\e907";
}
.icon-item-nav-icon-plan:before {
  content: "\e908";
}
.icon-item-nav-icon-venir:before {
  content: "\e909";
}
.icon-tel:before {
  content: "\e90a";
}
.icon-tw-icon:before {
  content: "\e90b";
}
.icon-vd-icon:before {
  content: "\e90c";
}
.icon-yt-icon:before {
  content: "\e90d";
}
