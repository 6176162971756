//- Feuille de style spécifique à la homepage

//- First homepage Section
.homepage-enter{
    padding-top: 0;
}

//- Homepage first section background
.homepage{

    .section-primary::before{
        background-image: url('./assets/img/homepage/header-pic.jpg');

        @include breakpoint($desktop){
            height: 500px;
        }
    }

}

//- Homepage title and excerpt container
.homepage-enter-title-container{
    position: relative;
    width: 100%;

    margin: 0 auto 15px;
    padding: 20px 10px 0;


    background: #FFF;

    transform: translateY(-10%);

    p{
        text-align: center;
        font-weight: 400;
        padding: 0 10px;
        margin-bottom: 20px;
    }


    .section-title::after{
        bottom: -25px;
    }

    @include breakpoint($tablet){
        width: 85%;
        padding: 50px 50px 0;
        margin: 0 auto -50px;
        transform: translateY(-25%);
        
        p{
            padding: 0;
            text-align: justify;
            text-align-last: left;
        }
    }

    @include breakpoint($desktop){
        width: 70%;
        padding: 50px 50px 0;
        margin: 0 auto -50px;

        transform: translateY(-25%);

        .section-title{
            line-height: calculateRem(48px);
        }

        p{
            padding: 0;
            text-align: justify;
            text-align-last: left;
        }
    }
}


//- Homepage first section - Chiffres / Stats
.homepage-stats{
    text-align: center;
    font-size: 0;

    &:hover, &:focus{
        text-decoration: none;
    }
}

.homepage-stats-item{
    display: block;
    margin-bottom: 40px;

    font: bold #{calculateRem(18px)}/#{calculateRem(18px)} $open_sans, sans-serif;
    text-transform: uppercase;
    color: $blue-darken;

    &:last-child{
        margin-bottom: 0;
    }

    &::after{
        display: none;
    }

    span{
        display: block;

        font: 500 #{calculateRem(36px)}/#{calculateRem(36px)} $oswald_regular, sans-serif;
        margin-bottom: 5px;
        text-align: center;
        color: $fushia;
    }

    @include breakpoint($tablet){
        display: inline-block;
        vertical-align: top;
        margin-bottom: 0;
        width: 20%;

        span{
            font: 500 #{calculateRem(38px)}/#{calculateRem(70px)} $oswald_regular, sans-serif;
            margin-bottom: 0;
        }
    }

    @include breakpoint($desktop){
        display: inline-block;
        margin-bottom: 0;
        width: 20%;

        span{
            font: 500 #{calculateRem(48px)}/#{calculateRem(70px)} $oswald_regular, sans-serif;
            margin-bottom: 0;
        }
    }

}

//- Stat item
.stat{
    display: none;
    margin: 0 auto 5px;
    height: 50px;

    @include breakpoint($tablet){
        display: block;
    }

    &-sign{
        background: url('./assets/img/homepage/sign.svg') no-repeat center;
    }

    &-megaphone{
        background: url('./assets/img/homepage/megaphone.svg') no-repeat center;
        //height: 41px; //- Reduce height like on psd
    }

    &-shake-hands{
        background: url('./assets/img/homepage/shake-hands.svg') no-repeat center;
    }

    &-signature{
        background: url('./assets/img/homepage/signature.svg') no-repeat center;
    }

    &-team{
        background: url('./assets/img/homepage/team.svg') no-repeat center;
    }

}

.homepage .outils-candidats{
    margin-top: calculateRem(50px);

    @include breakpoint($tablet){
        margin-top: 0;
    }
}
