//- Fix IE 11 .col-md
// _:-ms-fullscreen, :root .col-md-4{
:root .col-md-4{
    @include breakpoint($desktop){
        flex-basis: auto;
        // width: 33.33%;
    }
}



//- Nouvelle max-width du container, comme sur les maquettes
.container{
    max-width: 1320px;
}


.row-custom-gutter > [class*=col], .container.noGutter .row-custom-gutter>[class*=col] {
    display: block;
    width: 100%;
    //- Original gutter
    padding-right: 5px;
    padding-left: 5px;

    //- Custom gutters

    @include breakpoint($desktop){
        padding-right: 20px;
        padding-left: 20px;
    };

}
