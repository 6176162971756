.btn{
    display: inline-block;
    padding: 10px 25px;
    background: transparent;

    font: normal #{calculateRem(14px)}/#{calculateRem(14px)} $open_sans, sans-serif;
    text-decoration: none;
    color: $fushia;

    border: 1px solid $fushia;

    transition: border-color .2s ease-in-out, color .2s ease-in-out;

    cursor: pointer;

    &:focus, &:hover{
        border-color: $fushia-darken;
        text-decoration: none;
        color: $fushia-darken;
    }
}

.btn.btn-large{
    padding: 0;
    // display: block;
    display: inline-block;
    padding: 0 40px;

    background: $fushia;

    font: bold #{calculateRem(14px)}/#{calculateRem(60px)} $open_sans, sans-serif;
    text-align: center;
    color: #FFF;

    text-decoration: none;

    transition: background .2s ease-in-out;

    &:focus, &:hover{
        background: $fushia-darken;
    }
}
