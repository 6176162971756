.header-navigation .navigation-footer{
    position: absolute;
    padding: 20px 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: calculateRem(149px);

    background: #f4f5f8;
    border-top: solid 0.5px #e4e5e6;

    text-align: center;

    .icon-tel{
        font-size: calculateRem(16px)
    }

    .icon-combined-shape{
        font-size: calculateRem(10px)
    }

    // .icon-fb-icon{}

    .icon-tw-icon{
        font-size: calculateRem(24px)
    }

    .icon-yt-icon{
        font-size: calculateRem(28px)
    }
    .icon-in-icon{
        font-size: calculateRem(28px)
    }

}


.header-navigation .navigation-footer .contact-tools a{
    display: block;

    font: 600 #{calculateRem(16px)}/#{calculateRem(22px)} $open_sans, sans-serif;
    text-decoration: none;
    color: $blue-darken;

    border-bottom: none;
    i{
        margin-right: calculateRem(5px);
    }
}

.header-navigation .navigation-footer .contact-tel, .header-navigation .navigation-footer .contact-mail{
    margin-right: 0; //- Override previous margin in .header-1 > .contact-tel
    display: block;
    margin: 0 0 10px;
}

.header-navigation .navigation-footer .social-links{
    margin: 15px auto 0;
    font-size: 0;

    a{
        display: inline-block;
        padding: 0 20px;
        color: $blue-darken;
        font-size: calculateRem(36px);
        border-bottom: none;
    }

}
