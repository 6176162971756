//- Cookie Banner
.cookie-banner-message{
    background: $blue;
    border-top: 1px solid darken($blue, 10%);
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);

    .btn-save{
        @extend .btn;
        display: inline-block;
        margin-left: 10px;
        border-color: #FFF;
        background: transparent;
        color: #FFF;
        transition: color .2s ease-in-out, background .2s ease-in-out;

        cursor: pointer;

        &:hover, &:focus{
            background: #FFF;
            border-color: #FFF;
            color: $blue-darken;
        }
    }
}
