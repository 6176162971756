/* Based on
    Flexbox Grid : https://github.com/kristoferjoseph/flexboxgrid and
    Gridlex https://github.com/devlint/gridlex
*/

/* ==========================================================================
    LAYOUT
   ========================================================================== */

.container {
    margin-right: auto;
    margin-left: auto;
    padding-right: calc(20px);
    padding-left: calc(20px);
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-right: calc(20px);
    padding-left: calc(20px);
}

.container {
    max-width: 1280px;
}

.row {
    margin-right: calc(-1 * 5px);
    margin-left: calc(-1 * 5px);
}

@media (min-width: 641px) {

    .row {
        box-sizing: border-box;
        display: -webkit-box;
        display: flex;
        -webkit-box-flex: 0;
        flex: 0 1 auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

/* ==========================================================================
    GRID
   ========================================================================== */

[class*=col] {
    display: block;
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
}

[class*=col-hidden] {
    display: none;
}

@media (min-width: 641px) {

    [class*=col-xs] {
        width: auto;
        box-sizing: border-box;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        padding-right: 5px;
        padding-left: 5px;
    }

    .col-xs {
        -webkit-box-flex: 1;
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }/* Columns width */

    .col-xs-1 {
        flex-basis: calc( (100% / 12) * 1 );
        max-width: calc( (100% / 12) * 1 );
    }

    .col-fixed-xs-1 {
        width: calc( (1280px / 12) * 1 );
    }

    .col-xs-2 {
        flex-basis: calc( (100% / 12) * 2 );
        max-width: calc( (100% / 12) * 2 );
    }

    .col-fixed-xs-2 {
        width: calc( (1280px / 12) * 2 );
    }

    .col-xs-3 {
        flex-basis: calc( (100% / 12) * 3 );
        max-width: calc( (100% / 12) * 3 );
    }

    .col-fixed-xs-3 {
        width: calc( (1280px / 12) * 3 );
    }

    .col-xs-4 {
        flex-basis: calc( (100% / 12) * 4 );
        max-width: calc( (100% / 12) * 4 );
    }

    .col-fixed-xs-4 {
        width: calc( (1280px / 12) * 4 );
    }

    .col-xs-5 {
        flex-basis: calc( (100% / 12) * 5 );
        max-width: calc( (100% / 12) * 5 );
    }

    .col-fixed-xs-5 {
        width: calc( (1280px / 12) * 5 );
    }

    .col-xs-6 {
        flex-basis: calc( (100% / 12) * 6 );
        max-width: calc( (100% / 12) * 6 );
    }

    .col-fixed-xs-6 {
        width: calc( (1280px / 12) * 6 );
    }

    .col-xs-7 {
        flex-basis: calc( (100% / 12) * 7 );
        max-width: calc( (100% / 12) * 7 );
    }

    .col-fixed-xs-7 {
        width: calc( (1280px / 12) * 7 );
    }

    .col-xs-8 {
        flex-basis: calc( (100% / 12) * 8 );
        max-width: calc( (100% / 12) * 8 );
    }

    .col-fixed-xs-8 {
        width: calc( (1280px / 12) * 8 );
    }

    .col-xs-9 {
        flex-basis: calc( (100% / 12) * 9 );
        max-width: calc( (100% / 12) * 9 );
    }

    .col-fixed-xs-9 {
        width: calc( (1280px / 12) * 9 );
    }

    .col-xs-10 {
        flex-basis: calc( (100% / 12) * 10 );
        max-width: calc( (100% / 12) * 10 );
    }

    .col-fixed-xs-10 {
        width: calc( (1280px / 12) * 10 );
    }

    .col-xs-11 {
        flex-basis: calc( (100% / 12) * 11 );
        max-width: calc( (100% / 12) * 11 );
    }

    .col-fixed-xs-11 {
        width: calc( (1280px / 12) * 11 );
    }

    .col-xs-12 {
        flex-basis: calc( (100% / 12) * 12 );
        max-width: calc( (100% / 12) * 12 );
    }

    .col-fixed-xs-12 {
        width: calc( (1280px / 12) * 12 );
    }/* Columns offset */

    .col-xs-offset-1 {
        margin-left: calc( (100% / 12) * 1 );
    }

    .col-xs-offset-2 {
        margin-left: calc( (100% / 12) * 2 );
    }

    .col-xs-offset-3 {
        margin-left: calc( (100% / 12) * 3 );
    }

    .col-xs-offset-4 {
        margin-left: calc( (100% / 12) * 4 );
    }

    .col-xs-offset-5 {
        margin-left: calc( (100% / 12) * 5 );
    }

    .col-xs-offset-6 {
        margin-left: calc( (100% / 12) * 6 );
    }

    .col-xs-offset-7 {
        margin-left: calc( (100% / 12) * 7 );
    }

    .col-xs-offset-8 {
        margin-left: calc( (100% / 12) * 8 );
    }

    .col-xs-offset-9 {
        margin-left: calc( (100% / 12) * 9 );
    }

    .col-xs-offset-10 {
        margin-left: calc( (100% / 12) * 10 );
    }

    .col-xs-offset-11 {
        margin-left: calc( (100% / 12) * 11 );
    }

    .col-xs-offset-12 {
        margin-left: calc( (100% / 12) * 12 );
    }/* Hidden columns */

    .col-hidden-xs {
        display: -webkit-box;
        display: flex;
    }/* Position */

    .start-xs {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }

    .center-xs {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }

    .end-xs {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }

    .top-xs {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: start;
        align-items: flex-start;
    }

    .middle-xs {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
    }

    .bottom-xs {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: end;
        align-items: flex-end;
    }/* Distribution */

    .around-xs {
        display: -webkit-box;
        display: flex;
        justify-content: space-around;
    }

    .between-xs {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }/* Full Height */

    .fullHeight-xs > [class*=col] {
        min-height: 100vh;
    }/* Order */

    .first-xs {
        -webkit-box-ordinal-group: 0;
        order: -1;
    }

    .last-xs {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
}

@media (min-width: 769px) {

    [class*=col-sm] {
        width: auto;
        box-sizing: border-box;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        padding-right: 5px;
        padding-left: 5px;
    }

    .col-sm {
        -webkit-box-flex: 1;
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }/* Columns width */

    .col-sm-1 {
        flex-basis: calc( (100% / 12) * 1 );
        max-width: calc( (100% / 12) * 1 );
    }

    .col-fixed-sm-1 {
        width: calc( (1280px / 12) * 1 );
    }

    .col-sm-2 {
        flex-basis: calc( (100% / 12) * 2 );
        max-width: calc( (100% / 12) * 2 );
    }

    .col-fixed-sm-2 {
        width: calc( (1280px / 12) * 2 );
    }

    .col-sm-3 {
        flex-basis: calc( (100% / 12) * 3 );
        max-width: calc( (100% / 12) * 3 );
    }

    .col-fixed-sm-3 {
        width: calc( (1280px / 12) * 3 );
    }

    .col-sm-4 {
        flex-basis: calc( (100% / 12) * 4 );
        max-width: calc( (100% / 12) * 4 );
    }

    .col-fixed-sm-4 {
        width: calc( (1280px / 12) * 4 );
    }

    .col-sm-5 {
        flex-basis: calc( (100% / 12) * 5 );
        max-width: calc( (100% / 12) * 5 );
    }

    .col-fixed-sm-5 {
        width: calc( (1280px / 12) * 5 );
    }

    .col-sm-6 {
        flex-basis: calc( (100% / 12) * 6 );
        max-width: calc( (100% / 12) * 6 );
    }

    .col-fixed-sm-6 {
        width: calc( (1280px / 12) * 6 );
    }

    .col-sm-7 {
        flex-basis: calc( (100% / 12) * 7 );
        max-width: calc( (100% / 12) * 7 );
    }

    .col-fixed-sm-7 {
        width: calc( (1280px / 12) * 7 );
    }

    .col-sm-8 {
        flex-basis: calc( (100% / 12) * 8 );
        max-width: calc( (100% / 12) * 8 );
    }

    .col-fixed-sm-8 {
        width: calc( (1280px / 12) * 8 );
    }

    .col-sm-9 {
        flex-basis: calc( (100% / 12) * 9 );
        max-width: calc( (100% / 12) * 9 );
    }

    .col-fixed-sm-9 {
        width: calc( (1280px / 12) * 9 );
    }

    .col-sm-10 {
        flex-basis: calc( (100% / 12) * 10 );
        max-width: calc( (100% / 12) * 10 );
    }

    .col-fixed-sm-10 {
        width: calc( (1280px / 12) * 10 );
    }

    .col-sm-11 {
        flex-basis: calc( (100% / 12) * 11 );
        max-width: calc( (100% / 12) * 11 );
    }

    .col-fixed-sm-11 {
        width: calc( (1280px / 12) * 11 );
    }

    .col-sm-12 {
        flex-basis: calc( (100% / 12) * 12 );
        max-width: calc( (100% / 12) * 12 );
    }

    .col-fixed-sm-12 {
        width: calc( (1280px / 12) * 12 );
    }/* Columns offset */

    .col-sm-offset-1 {
        margin-left: calc( (100% / 12) * 1 );
    }

    .col-sm-offset-2 {
        margin-left: calc( (100% / 12) * 2 );
    }

    .col-sm-offset-3 {
        margin-left: calc( (100% / 12) * 3 );
    }

    .col-sm-offset-4 {
        margin-left: calc( (100% / 12) * 4 );
    }

    .col-sm-offset-5 {
        margin-left: calc( (100% / 12) * 5 );
    }

    .col-sm-offset-6 {
        margin-left: calc( (100% / 12) * 6 );
    }

    .col-sm-offset-7 {
        margin-left: calc( (100% / 12) * 7 );
    }

    .col-sm-offset-8 {
        margin-left: calc( (100% / 12) * 8 );
    }

    .col-sm-offset-9 {
        margin-left: calc( (100% / 12) * 9 );
    }

    .col-sm-offset-10 {
        margin-left: calc( (100% / 12) * 10 );
    }

    .col-sm-offset-11 {
        margin-left: calc( (100% / 12) * 11 );
    }

    .col-sm-offset-12 {
        margin-left: calc( (100% / 12) * 12 );
    }/* Hidden columns */

    .col-hidden-sm {
        display: -webkit-box;
        display: flex;
    }/* Position */

    .start-sm {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }

    .center-sm {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }

    .end-sm {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }

    .top-sm {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: start;
        align-items: flex-start;
    }

    .middle-sm {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
    }

    .bottom-sm {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: end;
        align-items: flex-end;
    }/* Distribution */

    .around-sm {
        display: -webkit-box;
        display: flex;
        justify-content: space-around;
    }

    .between-sm {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }/* Full Height */

    .fullHeight-sm > [class*=col] {
        min-height: 100vh;
    }/* Order */

    .first-sm {
        -webkit-box-ordinal-group: 0;
        order: -1;
    }

    .last-sm {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
}

@media (min-width: $desktop) {

    [class*=col-md] {
        width: auto;
        box-sizing: border-box;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        padding-right: 5px;
        padding-left: 5px;
    }

    .col-md {
        -webkit-box-flex: 1;
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }/* Columns width */

    .col-md-1 {
        flex-basis: calc( (100% / 12) * 1 );
        max-width: calc( (100% / 12) * 1 );
    }

    .col-fixed-md-1 {
        width: calc( (1280px / 12) * 1 );
    }

    .col-md-2 {
        flex-basis: calc( (100% / 12) * 2 );
        max-width: calc( (100% / 12) * 2 );
    }

    .col-fixed-md-2 {
        width: calc( (1280px / 12) * 2 );
    }

    .col-md-3 {
        flex-basis: calc( (100% / 12) * 3 );
        max-width: calc( (100% / 12) * 3 );
    }

    .col-fixed-md-3 {
        width: calc( (1280px / 12) * 3 );
    }

    .col-md-4 {
        flex-basis: calc( (100% / 12) * 4 );
        max-width: calc( (100% / 12) * 4 );
    }

    .col-fixed-md-4 {
        width: calc( (1280px / 12) * 4 );
    }

    .col-md-5 {
        flex-basis: calc( (100% / 12) * 5 );
        max-width: calc( (100% / 12) * 5 );
    }

    .col-fixed-md-5 {
        width: calc( (1280px / 12) * 5 );
    }

    .col-md-6 {
        flex-basis: calc( (100% / 12) * 6 );
        max-width: calc( (100% / 12) * 6 );
    }

    .col-fixed-md-6 {
        width: calc( (1280px / 12) * 6 );
    }

    .col-md-7 {
        flex-basis: calc( (100% / 12) * 7 );
        max-width: calc( (100% / 12) * 7 );
    }

    .col-fixed-md-7 {
        width: calc( (1280px / 12) * 7 );
    }

    .col-md-8 {
        flex-basis: calc( (100% / 12) * 8 );
        max-width: calc( (100% / 12) * 8 );
    }

    .col-fixed-md-8 {
        width: calc( (1280px / 12) * 8 );
    }

    .col-md-9 {
        flex-basis: calc( (100% / 12) * 9 );
        max-width: calc( (100% / 12) * 9 );
    }

    .col-fixed-md-9 {
        width: calc( (1280px / 12) * 9 );
    }

    .col-md-10 {
        flex-basis: calc( (100% / 12) * 10 );
        max-width: calc( (100% / 12) * 10 );
    }

    .col-fixed-md-10 {
        width: calc( (1280px / 12) * 10 );
    }

    .col-md-11 {
        flex-basis: calc( (100% / 12) * 11 );
        max-width: calc( (100% / 12) * 11 );
    }

    .col-fixed-md-11 {
        width: calc( (1280px / 12) * 11 );
    }

    .col-md-12 {
        flex-basis: calc( (100% / 12) * 12 );
        max-width: calc( (100% / 12) * 12 );
    }

    .col-fixed-md-12 {
        width: calc( (1280px / 12) * 12 );
    }/* Columns offset */

    .col-md-offset-1 {
        margin-left: calc( (100% / 12) * 1 );
    }

    .col-md-offset-2 {
        margin-left: calc( (100% / 12) * 2 );
    }

    .col-md-offset-3 {
        margin-left: calc( (100% / 12) * 3 );
    }

    .col-md-offset-4 {
        margin-left: calc( (100% / 12) * 4 );
    }

    .col-md-offset-5 {
        margin-left: calc( (100% / 12) * 5 );
    }

    .col-md-offset-6 {
        margin-left: calc( (100% / 12) * 6 );
    }

    .col-md-offset-7 {
        margin-left: calc( (100% / 12) * 7 );
    }

    .col-md-offset-8 {
        margin-left: calc( (100% / 12) * 8 );
    }

    .col-md-offset-9 {
        margin-left: calc( (100% / 12) * 9 );
    }

    .col-md-offset-10 {
        margin-left: calc( (100% / 12) * 10 );
    }

    .col-md-offset-11 {
        margin-left: calc( (100% / 12) * 11 );
    }

    .col-md-offset-12 {
        margin-left: calc( (100% / 12) * 12 );
    }/* Hidden columns */

    .col-hidden-md {
        display: -webkit-box;
        display: flex;
    }/* Position */

    .start-md {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }

    .center-md {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }

    .end-md {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }

    .top-md {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: start;
        align-items: flex-start;
    }

    .middle-md {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
    }

    .bottom-md {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: end;
        align-items: flex-end;
    }/* Distribution */

    .around-md {
        display: -webkit-box;
        display: flex;
        justify-content: space-around;
    }

    .between-md {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }/* Full Height */

    .fullHeight-md > [class*=col] {
        min-height: 100vh;
    }/* Order */

    .first-md {
        -webkit-box-ordinal-group: 0;
        order: -1;
    }

    .last-md {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
}

@media (min-width: 1281px) {

    [class*=col-lg] {
        width: auto;
        box-sizing: border-box;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        padding-right: 5px;
        padding-left: 5px;
    }

    .col-lg {
        -webkit-box-flex: 1;
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }/* Columns width */

    .col-lg-1 {
        flex-basis: calc( (100% / 12) * 1 );
        max-width: calc( (100% / 12) * 1 );
    }

    .col-fixed-lg-1 {
        width: calc( (1280px / 12) * 1 );
    }

    .col-lg-2 {
        flex-basis: calc( (100% / 12) * 2 );
        max-width: calc( (100% / 12) * 2 );
    }

    .col-fixed-lg-2 {
        width: calc( (1280px / 12) * 2 );
    }

    .col-lg-3 {
        flex-basis: calc( (100% / 12) * 3 );
        max-width: calc( (100% / 12) * 3 );
    }

    .col-fixed-lg-3 {
        width: calc( (1280px / 12) * 3 );
    }

    .col-lg-4 {
        flex-basis: calc( (100% / 12) * 4 );
        max-width: calc( (100% / 12) * 4 );
    }

    .col-fixed-lg-4 {
        width: calc( (1280px / 12) * 4 );
    }

    .col-lg-5 {
        flex-basis: calc( (100% / 12) * 5 );
        max-width: calc( (100% / 12) * 5 );
    }

    .col-fixed-lg-5 {
        width: calc( (1280px / 12) * 5 );
    }

    .col-lg-6 {
        flex-basis: calc( (100% / 12) * 6 );
        max-width: calc( (100% / 12) * 6 );
    }

    .col-fixed-lg-6 {
        width: calc( (1280px / 12) * 6 );
    }

    .col-lg-7 {
        flex-basis: calc( (100% / 12) * 7 );
        max-width: calc( (100% / 12) * 7 );
    }

    .col-fixed-lg-7 {
        width: calc( (1280px / 12) * 7 );
    }

    .col-lg-8 {
        flex-basis: calc( (100% / 12) * 8 );
        max-width: calc( (100% / 12) * 8 );
    }

    .col-fixed-lg-8 {
        width: calc( (1280px / 12) * 8 );
    }

    .col-lg-9 {
        flex-basis: calc( (100% / 12) * 9 );
        max-width: calc( (100% / 12) * 9 );
    }

    .col-fixed-lg-9 {
        width: calc( (1280px / 12) * 9 );
    }

    .col-lg-10 {
        flex-basis: calc( (100% / 12) * 10 );
        max-width: calc( (100% / 12) * 10 );
    }

    .col-fixed-lg-10 {
        width: calc( (1280px / 12) * 10 );
    }

    .col-lg-11 {
        flex-basis: calc( (100% / 12) * 11 );
        max-width: calc( (100% / 12) * 11 );
    }

    .col-fixed-lg-11 {
        width: calc( (1280px / 12) * 11 );
    }

    .col-lg-12 {
        flex-basis: calc( (100% / 12) * 12 );
        max-width: calc( (100% / 12) * 12 );
    }

    .col-fixed-lg-12 {
        width: calc( (1280px / 12) * 12 );
    }/* Columns offset */

    .col-lg-offset-1 {
        margin-left: calc( (100% / 12) * 1 );
    }

    .col-lg-offset-2 {
        margin-left: calc( (100% / 12) * 2 );
    }

    .col-lg-offset-3 {
        margin-left: calc( (100% / 12) * 3 );
    }

    .col-lg-offset-4 {
        margin-left: calc( (100% / 12) * 4 );
    }

    .col-lg-offset-5 {
        margin-left: calc( (100% / 12) * 5 );
    }

    .col-lg-offset-6 {
        margin-left: calc( (100% / 12) * 6 );
    }

    .col-lg-offset-7 {
        margin-left: calc( (100% / 12) * 7 );
    }

    .col-lg-offset-8 {
        margin-left: calc( (100% / 12) * 8 );
    }

    .col-lg-offset-9 {
        margin-left: calc( (100% / 12) * 9 );
    }

    .col-lg-offset-10 {
        margin-left: calc( (100% / 12) * 10 );
    }

    .col-lg-offset-11 {
        margin-left: calc( (100% / 12) * 11 );
    }

    .col-lg-offset-12 {
        margin-left: calc( (100% / 12) * 12 );
    }/* Hidden columns */

    .col-hidden-lg {
        display: -webkit-box;
        display: flex;
    }/* Position */

    .start-lg {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }

    .center-lg {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }

    .end-lg {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }

    .top-lg {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: start;
        align-items: flex-start;
    }

    .middle-lg {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
    }

    .bottom-lg {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: end;
        align-items: flex-end;
    }/* Distribution */

    .around-lg {
        display: -webkit-box;
        display: flex;
        justify-content: space-around;
    }

    .between-lg {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }/* Full Height */

    .fullHeight-lg > [class*=col] {
        min-height: 100vh;
    }/* Order */

    .first-lg {
        -webkit-box-ordinal-group: 0;
        order: -1;
    }

    .last-lg {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
}

/* ==========================================================================
    NO GUTTER
   ========================================================================== */

.row.noGutter {
    margin: 0;
}

.row.noGutter > [class*=col] {
    padding-left: 0;
    padding-right: 0;
}

.container.noGutter, .container-fluid.noGutter {
    padding-left: 0;
    padding-right: 0;
}

.container.noGutter .row, .container-fluid.noGutter .row {
    margin-left: 0;
    margin-right: 0;
}

.container.noGutter .row > [class*=col],.container-fluid.noGutter .row > [class*=col] {
    padding-left: 0;
    padding-right: 0;
}

/* ==========================================================================
    FULL HEIGHT
   ========================================================================== */

.row.fullHeight > [class*=col] {
    min-height: 100vh;
}

/* ==========================================================================
    EQUAL HEIGHT
   ========================================================================== */

.row.equalHeight > [class*=col] {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
}

.row.equalHeight > [class*=col] > * {
    -webkit-box-flex: 1;
    flex: 1 0 100%;
}

/* ==========================================================================
    POSITION
   ========================================================================== */

.row > .start {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
}

.row > .center {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
}

.row > .end {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
}

.row > .top {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
}

.row > .middle {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.row > .bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
    align-items: flex-end;
}

/* ==========================================================================
    DISTRIBUTION
   ========================================================================== */

.row.spaceBetween {
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.row.spaceAround {
    justify-content: space-around;
}

.row.center {
    -webkit-box-pack: center;
    justify-content: center;
}

/* ==========================================================================
    REVERSE
   ========================================================================== */

.row.reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
}

.col.reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
}
