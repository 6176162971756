/* -----------------------------------------
Box-sizing IE8+
----------------------------------------- */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

/* Corrects overflow displayed oddly in IE9 */
svg:not(:root) {
  overflow: hidden; }

/* -----------------------------------------
Generic tools
----------------------------------------- */
/* Hide from both screenreaders and browsers: h5bp.com/u */
.hidden, .no-text span {
  display: none !important;
  visibility: hidden; }

/* Hide only visually, but have it available for screenreaders: h5bp.com/v */
.visuallyhidden, .sr-only {
  border: 0;
  clip: rect(0);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
  margin: -1px;
  padding: 0; }

/* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p */
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  width: auto;
  margin: 0; }

/* Hide visually and from screenreaders, but maintain layout */
.invisible {
  visibility: hidden; }

/* Position, texts and links */
.left {
  float: left; }

.right {
  float: right; }

.clear {
  clear: both; }

.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix {
  *zoom: 1; }

span .h1, span .h2, span .h3, span .h4 {
  display: inline;
  vertical-align: middle; }

.center {
  margin-left: auto;
  margin-right: auto; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.link-right {
  display: block;
  text-align: right; }

.img-link {
  display: inline-block; }

.caps {
  text-transform: uppercase; }

.block {
  display: block; }

/* Automatic text cut to prevent text superposition : http://rocssti.nicolas-hoffmann.net/ */
.cut {
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -o-hyphens: auto;
  hyphens: auto; }

.nocut {
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -o-hyphens: none;
  hyphens: none; }

/* add ... if too long text-line, must specify a width to this container */
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis; }