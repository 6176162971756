.historique{

    .section-primary::before{
        background-image: url('./assets/img/historique/historique.jpg');
    }

    .btn-large{
        display: block;
        padding: 0;
        margin: 60px 0 0;
    }


    @media screen and (max-width: #{calculateRem($desktop)}){
        .section-primary-title{
            font: normal #{calculateRem(22px)}/#{calculateRem(28px)} $open_sans, sans-serif;
        }
    }

    .history-item:nth-child(n+10) .encart{
        display: none;
    }

}
