.gouvernance{

    .section-primary::before{
        background-image: url('./assets/img/gouvernance/gouvernance.jpg');
    }


    //- For space of section "Conseil d'Administration" like in template
    .section-primary .row:last-child{
        margin-top: 50px;
    }

    .download-link{
        display: block;
        margin-top: 20px;

        .icon-arrow:before{
            content: ''
        }
    }

    .bureau-col{
        margin-bottom: 30px;
    }

    @include breakpoint($desktop){
        .bureau-col{
            padding-right: 30px;
            margin-bottom: 0;
        }
    }

}


.gouvernance-contact{

    p, .contact-tel-large{
        font: 600 #{calculateRem(14px)}/#{calculateRem(26px)} $open_sans, sans-serif;
    }

    .text-bold{
        font-weight: bold;
    }

    .contact-tel-large i, .contact-mail-large i{
        position: absolute;
        top: 50%;
        left: 0;

        color: #c8c9cb;

        transform: translateY(-50%);
    }

    .contact-tel-large{
        position: relative;
        padding-left: 45px;
        margin: 25px 0 10px;

        i{
            font-size: calculateRem(28px);
        }
    }

    .contact-mail-large{
        position: relative;
        padding-left: 45px;
        margin: 10px 0 25px;


        a{
            font: 600 #{calculateRem(14px)}/#{calculateRem(26px)} $montserrat, sans-serif;
            text-decoration: underline;
        }


        i{
            font-size: calculateRem(18px);
        }
    }
}
